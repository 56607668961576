/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap');
@import "primeng/resources/primeng.css";
@import "@pv-frontend/pv-shared-components/resources/styles/theme.css";
@import "@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss";
@import "@pv-frontend/pv-shared-components/resources/styles/primeflex.css";

* {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-size: 16px;
  font-weight: 400;
}

.max-w-full {
  max-width: 1024px !important;
}

.gap-5px {
  gap: 5px;
}

.text-input-border {
  border: 1.5px solid #79879c17 !important;
}

.letter-spacing {
  letter-spacing: 4px;
}

.text-danger {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--interactive-text-negative-normal, #D92D20);
}

a {
  text-decoration: none;
}

a:link,
a:visited {
  color: var(--primary-color-text)
}

.consent {
  font-size: 10px;

  a:link,
  a:visited {
    color: var(--primary-color) !important;
  }
}

.h-36rem {
  height: 36rem;
}

.semi-bg-primary {
  background-color: #8195D8 !important;
}

:root {
  // --primary: #1E48D2 !important;
  // --black: #000000 !important;
  // --white: #FFFFFF !important;

  --yellow-ol-bg: #FDC832
}


.brand-btn {
  background-color: var(--primary);
}

.mobile-flex {
  display: none;
}

.m-cross {
  display: none;
}

@media only screen and (max-width: 786px) {
  .m-flex-column {
    flex-direction: column;
  }

  .letter-spacing {
    letter-spacing: 0.1rem;
  }

  .mobile-flex {
    display: flex;
  }

  .m-cross {
    display: block;
    position: absolute;
    right: 0;
  }

  .m-w-full {
    width: 100%;
  }

  .m-m-auto {
    margin: auto;
  }

  .m-px-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .m-text-center {
    text-align: center;
  }

  .m-sticky-bottom {
    position: -webkit-sticky;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }

  .m-justify-content-center {
    justify-content: center;
  }

  .mobileFaq {
    padding: 1rem;
  }
}

.transparent {
  opacity: 0.5;
}

button:disabled,
button[disabled] {
  border: 1px solid var(--disable-200);
  background-color: var(--disable-100);
  color: var(--disable-300);
}

:root {
  /**

    Variables coming out of primeflex
    We have changed their values as per theme

  **/

  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: rgba(0, 0, 0, 0.04);
  --surface-d: rgba(0, 0, 0, 0.12);
  --surface-e: #ffffff;
  --surface-f: #ffffff;

  // --text-color: rgba(0, 0, 0, 0.87);
  --black: hsla(0, 0%, 5%, 1);
  --black-secondary: hsla(0, 0%, 5%, 1);
  --text-color: #192839;
  --text-color-secondary: #435775;
  --primary-color: #1E48D2;
  --border-gray: #C7C7C7;
  // --primary-color-text: hsla(0, 0%, 100%, 1);
  --primary-color-text: #FFFFFF;
  --secondary-color-text: #1E48D2;
  --font-family: Poppins, 'arial', sans-serif;

  --secondary-color: hsla(340, 100%, 63%, 1);
  --secondary-color-hover: hsla(340, 100%, 63%, 0.92);
  --secondary-color-active: hsla(340, 100%, 63%, 0.68);
  --secondary-color-focus: hsla(340, 100%, 63%, 0.68);

  --background-secondary-color: hsla(182, 62%, 92%, 1);
  --background-secondary-color-hover: hsla(340, 100%, 63%, 0.04);
  --background-secondary-color-active: hsla(340, 100%, 63%, 0.16);
  --background-secondary-color-focus: hsla(340, 100%, 63%, 0.04);
  --background-voucher: hsla(227, 100%, 59%, 0.09);
  --border-voucher: hsla(211, 20%, 52%, 0.18);

  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;

  --disable-100: #cccccc;
  --disable-200: #999999;
  --disable-300: #666666;

  --box-shadow-1: rgba(0, 0, 0, 0.2);
  --box-shadow-2: rgba(0, 0, 0, 0.14);
  --box-shadow-3: var(--surface-border);

  --gray-50: hsla(216, 15%, 54%, 0.09);
  --gray-100: hsla(216, 15%, 54%, 0.18);
  --gray-200: hsla(220, 27%, 98%, 1);
  --gray-300: hsla(220, 30%, 96%, 1);
  --gray-400: hsla(216, 19%, 89%, 1);
  --gray-500: hsla(214, 18%, 69%, 1);
  --gray-600: hsla(216, 16%, 60%, 1);
  --gray-700: hsla(217, 18%, 45%, 1);
  --gray-800: hsla(216, 33%, 29%, 1);
  --gray-900: hsla(217, 56%, 17%, 1);

  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 4px;
  --surface-ground: #fafafa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: var(--surface-border-normal);
  --surface-hover: rgba(0, 0, 0, 0.04);

  --maskbg: rgba(0, 0, 0, 0.32);

  --focus-ring: none;
  --highlight-bg: rgba(63, 81, 181, 0.12);
  --highlight-text-color: var(--primary-color);
  color-scheme: light;

  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f7faf5;
  --green-100: #dbe8cf;
  --green-200: #bed6a9;
  --green-300: #a1c384;
  --green-400: #85b15e;
  --green-500: #689f38;
  --green-600: #588730;
  --green-700: #496f27;
  --green-800: #39571f;
  --green-900: #2a4016;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f6f7fc;
  --indigo-100: #d5d9ef;
  --indigo-200: #b3bae2;
  --indigo-300: #919cd5;
  --indigo-400: #707dc8;
  --indigo-500: #4e5fbb;
  --indigo-600: #42519f;
  --indigo-700: #374383;
  --indigo-800: #2b3467;
  --indigo-900: #1f264b;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fffaf2;
  --orange-100: #ffe6c2;
  --orange-200: #ffd391;
  --orange-300: #ffbf61;
  --orange-400: #ffac30;
  --orange-500: #ff9800;
  --orange-600: #d98100;
  --orange-700: #b36a00;
  --orange-800: #8c5400;
  --orange-900: #663d00;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --red-50: #fef6f5;
  --red-100: #fcd2cf;
  --red-200: #faaea9;
  --red-300: #f88a82;
  --red-400: #f6675c;
  --red-500: #f44336;
  --red-600: #cf392e;
  --red-700: #ab2f26;
  --red-800: #86251e;
  --red-900: #621b16;
  --primary-50: #f5f6fb;
  --primary-100: #d1d5ed;
  --primary-200: #acb4df;
  --primary-300: hsla(218, 89%, 51%, 0.09);
  --primary-400: hsla(218, 89%, 51%, 0.18);
  --primary-500: hsla(218, 89%, 51%, 1);
  --primary-600: hsla(223, 95%, 48%, 1);
  --primary-700: hsla(227, 100%, 45%, 1);
  --primary-800: hsla(234, 100%, 34%, 1);

  /** From here on, our custom variables **/

  --brand-primary-300: hsla(218, 89%, 51%, 0.09);
  --brand-primary-400: hsla(218, 89%, 51%, 0.18);
  --brand-primary-500: hsla(218, 89%, 51%, 1);
  --brand-primary-600: hsla(223, 95%, 48%, 1);
  --brand-primary-700: hsla(227, 100%, 45%, 1);

  --brand-gray-200-low-contrast: hsla(220, 27%, 98%, 1);
  --brand-gray-300-low-contrast: hsla(220, 30%, 96%, 1);
  --brand-gray-400-low-contrast: hsla(216, 19%, 89%, 1);
  --brand-gray-500-low-contrast: hsla(214, 18%, 69%, 1);
  --brand-gray-600-low-contrast: hsla(216, 16%, 60%, 1);
  --brand-gray-700-low-contrast: hsla(217, 18%, 45%, 1);

  --brand-gray-200-high-contrast: hsla(216, 33%, 29%, 1);
  --brand-gray-300-high-contrast: hsla(216, 27%, 36%, 1);
  --brand-gray-400-high-contrast: hsla(217, 18%, 45%, 1);
  --brand-gray-500-high-contrast: hsla(216, 15%, 54%, 1);
  --brand-gray-600-high-contrast: hsla(214, 18%, 69%, 1);
  --brand-gray-700-high-contrast: hsla(220, 30%, 96%, 1);

  --surface-text-normal-low-contrast: hsla(217, 56%, 17%, 1);
  --surface-text-subtle-low-contrast: hsla(216, 27%, 36%, 1);
  --surface-text-subdued-low-contrast: hsla(217, 18%, 45%, 1);
  --surface-text-muted-low-contrast: hsla(216, 16%, 60%, 1);
  --surface-text-placeholder-low-contrast: hsla(214, 18%, 69%, 1);

  --surface-text-normal-high-contrast: hsla(0, 0%, 100%, 1);
  --surface-text-subtle-high-contrast: hsla(220, 30%, 96%, 1);
  --surface-text-subdued-high-contrast: hsla(216, 19%, 89%, 1);
  --surface-text-muted-high-contrast: hsla(218, 19%, 81%, 1);
  --surface-text-placeholder-high-contrast: hsla(216, 15%, 54%, 1);

  --surface-background-level1-low-contrast: hsla(220, 30%, 96%, 1);
  --surface-background-level2-low-contrast: hsla(0, 0%, 100%, 1);
  --surface-background-level3-low-contrast: hsla(220, 27%, 98%, 1);

  --surface-background-level1-high-contrast: hsla(217, 56%, 17%, 1);
  --surface-background-level2-high-contrast: hsla(216, 44%, 23%, 1);
  --surface-background-level3-high-contrast: hsla(216, 33%, 29%, 1);

  --surface-action-icon-low-contrast: hsla(214, 18%, 69%, 1);
  --surface-action-icon-high-contrast: hsla(214, 21%, 94%, 0.32);

  --surface-action-icon-low-contrast-active: hsla(217, 18%, 45%, 1);

  --surface-border-normal: hsla(216, 15%, 54%, 0.18);
  --surface-border-subtle: hsla(216, 15%, 54%, 0.09);

  --feedback-background-positive-low-contrast: hsla(155, 100%, 31%, 0.18);
  --feedback-background-negative-low-contrast: hsla(9, 91%, 56%, 0.18);
  --feedback-background-notice-low-contrast: hsla(36, 100%, 44%, 0.09);
  --feedback-background-information-low-contrast: hsla(193, 100%, 35%, 0.09);
  --feedback-background-neutral-low-contrast: hsla(216, 15%, 54%, 0.09);

  --feedback-background-positive-high-contrast: hsla(160, 100%, 26%, 1);
  --feedback-background-negative-high-contrast: hsla(8, 73%, 47%, 1);
  --feedback-background-notice-high-contrast: hsla(38, 97%, 38%, 1);
  --feedback-background-information-high-contrast: hsla(193, 100%, 35%, 1);
  --feedback-background-neutral-high-contrast: hsla(216, 33%, 29%, 1);

  --feedback-border-positive-low-contrast: hsla(155, 100%, 31%, 0.32);
  --feedback-border-negative-low-contrast: hsla(9, 91%, 56%, 0.32);
  --feedback-border-notice-low-contrast: hsla(36, 100%, 44%, 0.32);
  --feedback-border-information-low-contrast: hsla(193, 100%, 35%, 0.32);
  --feedback-border-neutral-low-contrast: hsla(216, 15%, 54%, 0.32);

  --feedback-border-positive-high-contrast: hsla(160, 100%, 26%, 1);
  --feedback-border-negative-high-contrast: hsla(8, 73%, 47%, 1);
  --feedback-border-notice-high-contrast: hsla(38, 97%, 38%, 1);
  --feedback-border-information-high-contrast: hsla(193, 100%, 35%, 1);
  --feedback-border-neutral-high-contrast: hsla(216, 33%, 29%, 1);

  --feedback-text-positive: hsla(160, 100%, 26%, 1);
  --feedback-text-negative: hsla(8, 73%, 47%, 1);
  --feedback-text-notice: hsla(38, 97%, 38%, 1);
  --feedback-text-information: hsla(193, 100%, 35%, 1);
  --feedback-text-neutral: hsla(216, 27%, 36%, 1);

  /** Classes yet to be created for below variables **/

  --feedback-action-primary-border-notice-high-contrast-disabled: hsla(35,
      84%,
      54%,
      0.8);
  --surface-action-icon-low-contrast-hover: hsla(217, 18%, 45%, 1);
  --surface-action-icon-low-contrast-disabled: hsla(216, 19%, 89%, 1);

  --surface-action-icon-high-contrast-active: hsla(0, 0%, 100%, 1);
  --surface-border-normal-low-contrast: hsla(233, 11%, 84%, 0.18);

  --feedback-background-neutral-low-contrast-hover: hsla(216, 15%, 54%, 0.18);
  --feedback-background-neutral-low-contrast-focus: hsla(216, 15%, 54%, 0.32);
  --feedback-background-neutral-low-contrast-active: hsla(216, 15%, 54%, 0.32);
  --feedback-background-neutral-low-contrast-disabled: hsla(216,
      15%,
      54%,
      0.09);

  --feedback-background-info-low-contrast-hover: hsla(193, 100%, 35%, 0.18);
  --feedback-background-info-low-contrast-focus: hsla(193, 100%, 35%, 0.32);
  --feedback-background-info-low-contrast-active: hsla(193, 100%, 35%, 0.32);
  --feedback-background-info-low-contrast-disabled: hsla(193, 100%, 35%, 0.09);

  --feedback-background-positive-low-contrast-hover: hsla(155, 100%, 31%, 0.18);
  --feedback-background-positive-low-contrast-focus: hsla(155, 100%, 31%, 0.32);
  --feedback-background-positive-low-contrast-active: hsla(155,
      100%,
      31%,
      0.32);
  --feedback-background-positive-low-contrast-disabled: hsla(155,
      100%,
      31%,
      0.18);

  --feedback-background-notice-low-contrast-hover: hsla(36, 100%, 44%, 0.18);
  --feedback-background-notice-low-contrast-focus: hsla(36, 100%, 44%, 0.32);
  --feedback-background-notice-low-contrast-active: hsla(36, 100%, 44%, 0.32);
  --feedback-background-notice-low-contrast-disabled: hsla(36, 100%, 44%, 0.18);

  --feedback-background-negative-low-contrast-hover: hsla(9, 91%, 56%, 0.18);
  --feedback-background-negative-low-contrast-focus: hsla(9, 91%, 56%, 0.32);
  --feedback-background-negative-low-contrast-active: hsla(9, 91%, 56%, 0.32);
  --feedback-background-negative-low-contrast-disabled: hsla(9, 91%, 56%, 0.18);

  --feedback-background-neutral-high-contrast-hover: hsla(216, 44%, 23%, 1);
  --feedback-background-neutral-high-contrast-focus: hhsla(217, 56%, 17%, 1);
  --feedback-background-neutral-high-contrast-active: hsla(217, 56%, 17%, 1);
  --feedback-background-neutral-high-contrast-disabled: hsla(216, 33%, 29%, 1);

  --feedback-background-info-high-contrast-hover: hsla(195, 100%, 31%, 1);
  --feedback-background-info-high-contrast-focus: hsla(196, 100%, 27%, 1);
  --feedback-background-info-high-contrast-active: hsla(196, 100%, 27%, 1);
  --feedback-background-info-high-contrast-disabled: hsla(193, 100%, 35%, 1);

  --feedback-background-positive-high-contrast-hover: hsla(163, 100%, 22%, 1);
  --feedback-background-positive-high-contrast-focus: hsla(164, 100%, 17%, 1);
  --feedback-background-positive-high-contrast-active: hsla(164, 100%, 17%, 1);
  --feedback-background-positive-high-contrast-disabled: hsla(160,
      100%,
      26%,
      1);

  --feedback-background-notice-high-contrast-hover: hsla(40, 90%, 32%, 1);
  --feedback-background-notice-high-contrast-focus: hsla(41, 82%, 26%, 1);
  --feedback-background-notice-high-contrast-active: hsla(41, 82%, 26%, 1);
  --feedback-background-notice-high-contrast-disabled: hsla(38, 97%, 38%, 1);

  --feedback-background-negative-high-contrast-hover: hsla(7, 72%, 40%, 1);
  --feedback-background-negative-high-contrast-focus: hsla(5, 69%, 32%, 1);
  --feedback-background-negative-high-contrast-active: hsla(5, 69%, 32%, 1);
  --feedback-background-negative-high-contrast-disabled: hsla(8, 73%, 47%, 1);

  /* Offer Card*/
  --box-shadow-gray-1: rgba(17, 17, 26, 0.05);
  --box-shadow-gray-2: rgba(17, 17, 26, 0.1);

  /* Custom Variables */
  --black-teritiary: hsla(0, 0%, 15%, 1);
  --gray-secondary: hsla(0, 0%, 45%);
  --gray-tertiary: hsla(0, 0%, 65%, 1);
  --gray-quaternary: hsla(0, 0%, 32%, 1);
  --card-shadow: rgba(0, 0, 0, 0.16);
  --black: hsla(0, 0%, 0%, 1);
  --black-secondary: hsla(0, 0%, 27%, 1);
  --gray-light: hsla(211, 22%, 56%, 1);

  /* GV BOGO Specific */
  --bogo-card-ribbon-default-gradient: linear-gradient(245deg,
      #4674e8 -11.04%,
      #4f397c 92.45%);
  /* Ketto Specific */
  --k-light-primary: hsl(183, 57%, 36%);
  --k-dark-primary: hsl(183, 56%, 26%);
  /*T&C */
  --terms-condions-text: #0E202E;
  --disclaimer: #999;
}

.bg-primary {
  color: var(--primary-color-text);
  background-color: var(--primary-color);
}

.btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-transform: none;
}